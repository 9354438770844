<template>
  <div class="w-100 h-100 bg-neutral-light">
    <form
      @submit="update"
      novalidate
      class="flex-column align-items-center wd-100p wd-md-50p"
      :class="{ 'was-validated': wasValidated }"
    >
      <div class="">
        <div class="row mb-3 expand-p3 p-3">
          <div class="col-12 mb-3 text-align-right-on-arabic">
            <label for="fullName">Full Name</label>
            <input
              id="fullName"
              name="name"
              type="text"
              class="form-control"
              v-model="fullName"
              required
            />
            <div class="invalid-feedback">Please fill out this field.</div>
          </div>
          <div class="col-6 mb-3 text-align-right-on-arabic">
            <label for="password">Email</label>
            <input
              id="password"
              name="email"
              type="email"
              class="form-control"
              v-model="email"
              required
            />
          </div>
          <div class="col-6 mb-3 text-align-right-on-arabic">
            <label for="phone-number">Phone Number</label>
            <phone-input
              class="form-control d-flex p-0"
              :value="phone_number"
              @input="setNewNumber"
            />
            <div
              v-if="phoneObject && !phoneObject.valid"
              class="invalid-feedback d-block"
            >
              Please enter a valid phone number
            </div>
          </div>
          <div class="col-6 mb-3 text-align-right-on-arabic">
            <label for="password">Password</label>
            <input
              name="password"
              type="password"
              class="form-control"
              v-model="password"
              required
            />
          </div>
          <div class="col-6 mb-2 text-align-right-on-arabic">
            <label for="passwordconfirm">Confirm Password</label>
            <input
              name="passwordconfirm"
              type="password"
              class="form-control"
              v-model="passwordConfirm"
              required
            />
          </div>
          <div class="col-12 mb-2">
            <center>
              <div class="text-danger my-2" v-if="this.errorMessage != ''">
                {{ this.errorMessage }}
              </div>
            </center>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="rounded-button bg-primary text-neutral-light mx-auto"
        value="submit"
        :disabled="submitting"
      >
        <img
          v-show="submitting"
          v-bind:src="require('../assets/images/loading-white.gif')"
          class="mx-2"
        />
        Sign Up
      </button>
    </form>
  </div>
</template>

<script>
import PhoneInput from "@/components/PhoneInput";
export default {
  name: "Profile",
  components: { PhoneInput },
  data() {
    return {
      email: null,
      password: "",
      fullName: null,
      passwordConfirm: "",
      company_id: "",
      errorMessage: "",
      submitting: false,
      iti: null,
      phone_number: null,
      phoneObject: null,
      wasValidated: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    setNewNumber(phoneObject) {
      this.phone_number = phoneObject.formatted;
      this.phoneObject = phoneObject;
    },
    update() {},
  },
  watch: {
    user: {
      handler(newVal, oldVal) {
        console.log(newVal);
        console.log(oldVal);
        if (newVal) {
          this.email = newVal.email;
          this.phone_number = newVal.phone_number;
          this.fullName = newVal.name;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
